import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi-browser";
import { CurrencyFormat } from "utils/currencyFormat";
import { MonthName } from "utils/dateFormat";
import Countdown, { zeroPad } from "react-countdown";
import queryString from "query-string";
import logger from "utils/logger";
import copy from 'copy-to-clipboard';
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { Dialog } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appStore from "assets/images/icons/app-store.png";
import playStore from "assets/images/icons/play-store.png";
import { ReactComponent as IconWave } from "assets/images/newDesignAssets/svgs/Wave.svg";
import { ReactComponent as IconChartToggle } from "assets/images/icons/project-icons/IconToggle.svg";
import { ReactComponent as IconWithdraw } from "assets/images/icons/project-icons/Withdraw.svg";
import { ReactComponent as IconBag } from "assets/images/icons/project-icons/Combo.svg";
// import phoneDownload from "assets/images/bgs/phone-download-3x.png";
import phoneDownload from "assets/images/bgs/phone-download-3x.png";
// import phonemint from "assets/images/bgs/mint.png";

import {
	getDashboard,
	getWithdrawalFee,
	getAutopayFee,
	newWithdrawal,
	resetNewWithdrawal,
	getReasonsForWithdraw,
} from "store/entities/dashboard/action";
import {
	getAllWithdrawals,
	next,
	prev,
	resetWithdrawalForm,
	resetReloadPage,
	// createWithdrawalSchedule,
	getWithdrawalSchedule,
	// getAllWithdrawalSchedule,
	updateWithdrawalSchedule,
	deleteWithdrawalSchedule,
	// activateAutopay,
	cancelAutopay,
} from "store/entities/withdrawals/action";
// import { getNotifications } from "store/entities/notifications/action";
// import { getTermsOfTheDay } from "../../../../store/entities/educations/action";
import * as AccountActions from "store/auth/account/action";

// components
import WithdrawalModal from "../earnipay/WithdrawalModal";
import { PaymentModal } from "../account/PaymentModals";
import CurrentDate from "utils/currentDate";
import ComingSoonModal from "./ComingSoonModal";
// import { CutoffModal, CutoffComponent } from "./Cutoff";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";
import ContentLoading from "components/common/ContentLoading";
import BannerAlert from "components/common/BannerAlert";

const Dashboard = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [withdrawalForm, setWithdrawalForm] = useState({
		amount: "",
		purpose: null,
		employerId: "",
		transactionFee: "",
		amountBalanceWithdrawal: "",
		userReason: "",
		destinationAccount: "earnipay-account",
	});
	const [, setAutoWithdrawals] = useState("");
	const [withdrawalPercent, setWithdrawalPercent] = useState(null);
	const [paymentFeeBy, setPaymentFeeBy] = useState("");
	const [errors, setErrors] = useState({});
	const [open, setOpen] = useState(false);
	const [allWithdrawalParams, setAllWithdrawalsParams] = useState({
		page: 1,
		limit: 0,
	});
	// eslint-disable-next-line no-unused-vars
	const [isCutOff, setIsCutOff] = useState(false);
	const [paymentDueDate, setPaymentDueDate] = useState("");
	const [validateAction, setValidateAction] = useState("");
	const [active, setActive] = useState(false);
	const [clickedDate, setClickedDate] = useState(false);
	const [countdown, setCoundown] = useState("");
	const [, setCurrentMonth] = useState("");
	const [progressBar, setProgressBar] = useState(0);

	const completeButtonRef = React.createRef();

	const user = useSelector((s) => s.auth.userPersist.data);
	
	const {
		loading: loadingDashboard,
		allDashboard,
		autopayFee,
		autopayFeeLoading,
		withdrawalFee,
		withdrawalFeeLoading,
		newWithdrawal: newlyWithdraw,
		newWithdrawalSuccess,
		loadingNewWithdrawal,
		loadingReasons,
		successReasonWithdrawal,
		withdrawalReasons,
	} = useSelector((s) => s.entities.dashboard);
	// const { allWithdrawals, loading: loadingWithdrawals, current, withdrawalParams, schedule, secheduleGet, schedules, loading: loadingGetSchedule, loading: loadingDelete, updatedSchedule, loading: loadingUpdate, isDeletedSchedule, loading: loadingCreateWithdrawal } = useSelector(s => s.entities.withdrawals);
	const {
		allWithdrawals,
		current,
		withdrawalParams,
		schedule,
		secheduleGet,
		schedules,
		loading: loadingGetSchedule,
		loading: loadingDelete,
		updatedSchedule,
		loading: loadingUpdate,
		isDeletedSchedule,
		loading: loadingCreateWithdrawal,
		autopayCancellationFee,
		loading: loadingCancelAutopay,
	} = useSelector((s) => s.entities.withdrawals);
	const { notifications } = useSelector((s) => s.entities.notifications);
	const { walletDetailsLoading, walletDetails } = useSelector((s) => s.auth.account);

	const listAllWithdrawals = () => {
		const qs = queryString.parse(props.location.search);
		let allWithdrawalsParams = { ...allWithdrawalParams };

		const limit = 0;

		if (qs.page !== undefined && !isNaN(qs.page) && qs.page > 0) {
			allWithdrawalsParams = { ...allWithdrawalsParams, page: qs.page };
		} else {
			allWithdrawalsParams = { ...allWithdrawalsParams, page: 1 };
		}

		if (qs.limit !== undefined && !isNaN(qs.limit) && qs.limit > 0) {
			allWithdrawalsParams = { ...allWithdrawalsParams, limit: qs.limit };
		} else {
			allWithdrawalsParams = { ...allWithdrawalsParams, limit: limit };
		}

		setAllWithdrawalsParams({ allWithdrawalsParams });
		dispatch(getAllWithdrawals(allWithdrawalsParams));
		// dispatch(getNotifications(allWithdrawalsParams));
	};
	const date = new Date().getDate();
	const getYear = new Date().getFullYear();
	const getMonth = new Date().getMonth() + 1;
	const daysOfMonth = new Date(getYear, getMonth, 0).getDate();
	const memorizedDetails = useMemo(
		() => allDashboard?.dashboard || [],
		[allDashboard]
	);
	const memorizedReasonWithdrawal = useMemo(
		() => withdrawalReasons?.reasons || [],
		[withdrawalReasons.reasons]
	);

	const memorizedWithdrawals = useMemo(
		() => allWithdrawals?.withdrawals?.docs || [],
		[allWithdrawals?.withdrawals?.docs]
	);
	const { companyInfo, groupPolicy, totalAccured, totalAmountWithdrawn } =
		memorizedDetails;

	const balanceWithdrawable = Number(
		(withdrawalPercent / 100) * totalAccured - totalAmountWithdrawn
	).toFixed(2);
	const employerId = allDashboard?.dashboard?.companyInfo?.employerId;
	const memorizedSchedules = useMemo(
		() => schedules?.schedules?.docs || [],
		[schedules?.schedules?.docs]
	);
	const memoizedNotes = useMemo(
		() => (notifications || []).filter((item) => item.isRead === false),
		[notifications]
	);

	const [open2, setOpen2] = useState(false);
	const [openComingSoon, setOpenComingSoon] = useState(false);
	const [openCutOff, setOpenCutOff] = useState(false);
	const [currentScreenSchedule, setCurrent] = useState(1);
	const [autoWithdrawalForm, setAutoWithdrawalForm] = useState({
		amount: "",
		frequency: "",
		startDate: "",
		endDate: "",
		repeatIntervalDay: "",
		repeatIntervalWeek: "",
		weekday: "",
		dayOfMonth: "",
	});

	const [passwordForm, setpasswordForm] = useState({
		newpin1: "",
		newpin2: "",
		newpin3: "",
		newpin4: "",
	});

	const [bgCol, setBgCol] = useState({
		box1: "#F3F4F6",
		box2: "#F3F4F6",
		box3: "#F3F4F6",
		box4: "#F3F4F6",
	});

	const addBgCol = () => {
		const { newpin1, newpin2, newpin3, newpin4 } = passwordForm;
		const newBgCol = { ...bgCol };
		newBgCol.box1 = newpin1 ? "#374151" : "";
		newBgCol.box2 = newpin2 ? "#374151" : "";
		newBgCol.box3 = newpin3 ? "#374151" : "";
		newBgCol.box4 = newpin4 ? "#374151" : "";
		setBgCol({ ...newBgCol });
	};

	const [displayBalanceAccrued, setDisplayBalanceAccrued] = useState(true);
	const [displayBalanceWithdraw, setDisplayBalanceWithdraw] = useState(true);
	const [displayBalanceWallet, setDisplayBalanceWallet] = useState(true);
	const [isOpenKycRequired, setIsOpenKycRequired] = useState(false);

	const handleSetCurrent = (n) => setCurrent(n);

	const handleTogglePayment = () => {
		if (user.autoPaySetup === false) {
			handleSetCurrent(1);
			setOpen2(!open2);
		}
	};

	const allowAutopayToggle = () => {
		if (user.autoPaySetup === false) {
			handleSetCurrent(1);
			setOpen2(!open2);
			setOpen(false);
		} else {
			handleSetCurrent(4);
			setOpen2(true);
			setOpen(false);
		}
	};
	
	const handleChangeAutoPay = (e) => {
		setAutoWithdrawalForm({
			...autoWithdrawalForm,
			employerId: employerId,
			[e.target.name]: e.target.value,
		});
	};

	const cloaseModalAndClearFormFields = () => {
		setWithdrawalForm({
			amount: "",
			purpose: "",
			transactionFee: 0,
			amountBalanceWithdrawal: 0,
			destinationAccount: "earnipay-account",
			employerId: "",
			userReason: "",
		});
		setpasswordForm({
			newpin1: "",
			newpin2: "",
			newpin3: "",
			newpin4: "",
		});
		setBgCol({
			box1: "#F3F4F6",
			box2: "#F3F4F6",
			box3: "#F3F4F6",
			box4: "#F3F4F6",
		});
		dispatch(resetWithdrawalForm());
		setOpen(false);
	};
	const formChange = (e) => {
		setpasswordForm({
			...passwordForm,
			[e.currentTarget.name]: e.currentTarget.value,
		});
	};
	const clearAutoWithdrawalForm = () => {
		setAutoWithdrawalForm({
			amount: "",
			frequency: "",
			startDate: "",
			endDate: "",
			repeatIntervalDay: "",
			repeatIntervalWeek: "",
			weekday: "",
			dayOfMonth: "",
		});
		setpasswordForm({ newpin1: "", newpin2: "", newpin3: "", newpin4: "" });
		setBgCol({
			box1: "#F3F4F6",
			box2: "#F3F4F6",
			box3: "#F3F4F6",
			box4: "#F3F4F6",
		});
	};

	const clearPasswordForm = () => {
		setpasswordForm({ newpin1: "", newpin2: "", newpin3: "", newpin4: "" });
		setBgCol({
			box1: "#F3F4F6",
			box2: "#F3F4F6",
			box3: "#F3F4F6",
			box4: "#F3F4F6",
		});
	};

	const handleInputScheduleOnEdit = (name, value) => {
		const form = autoWithdrawalForm;
		form[name] = value;
		setAutoWithdrawalForm(form);
		setAutoWithdrawalForm({ ...form });
	};

	const getScheduleById = (id) => {
		if (memorizedSchedules?.length > 0) {
			dispatch(getWithdrawalSchedule(id));
		} else {
			toast.error("No payment schedules found");
		}
	};

	const displayAutopayFees = () => {
		let withdrawalForm2 = {};
		const { amount } = autoWithdrawalForm;
		let amountBalanceWithdrawal = 0;

		if (amount >= 500) {
			dispatch(getWithdrawalFee({ amount, employerId }))
				.then(() => {
					let transactionFee = Number(withdrawalFee.fee);
					if (paymentFeeBy === "employee") {
						amountBalanceWithdrawal = Number(amount - transactionFee);
					} else if (paymentFeeBy === "employer") {
						transactionFee = 0;
						amountBalanceWithdrawal = amount;
					} else if (paymentFeeBy === "shared") {
						transactionFee = transactionFee / 2;
						amountBalanceWithdrawal = Number(amount - transactionFee);
					}
					withdrawalForm2 = {
						...autoWithdrawalForm,
						transactionFee,
						amountBalanceWithdrawal,
					};

					setAutoWithdrawalForm(withdrawalForm2);
				})
				.catch((err) => {
					withdrawalForm2 = {
						...autoWithdrawalForm,
						transactionFee: 0,
						amountBalanceWithdrawal: 0,
					};
					setAutoWithdrawalForm({
						...autoWithdrawalForm,
						transactionFee: 0,
						amountBalanceWithdrawal: 0,
					});
				});
		} else {
			setAutoWithdrawalForm({
				...autoWithdrawalForm,
				transactionFee: 0,
				amountBalanceWithdrawal: 0,
			});
		}
	};

	const deleteSchedulePayment = (id) => {
		const userPin = Number(
			passwordForm.newpin1 +
				passwordForm.newpin2 +
				passwordForm.newpin3 +
				passwordForm.newpin4
		);
		const params = {
			pin: userPin,
		};
		dispatch(deleteWithdrawalSchedule(id, params));
	};

	const cancelAutopaySchedule = (id) => dispatch(cancelAutopay(id));

	const copyValue = (value) => {
		if (value){
			copy(value);
			toast.success("Copied: " + value);
		}
    }

	const schemaAutopay = {
		amount: Joi.number()
			.required()
			.min(500)
			.max(50000)
			.label("Amount")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "number.base":
							err.message = `Please enter the amount to to schedule for autopay`;
							break;
						case "number.min":
							err.message = `Minimum amount for this plan: ₦2,000`;
							break;
						case "number.max":
							err.message = `Maximum amount for this plan: ₦50,000`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		frequency: Joi.string()
			.required()
			.label("Frequency")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "empty":
							err.message = `Please select an option from the list`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
	};

	const validateAutopay = () => {
		const options = { abortEarly: false, allowUnknown: true };
		const result = Joi.validate(autoWithdrawalForm, schemaAutopay, options);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	const handleCreateSchedule = () => {
		const errors = validateAutopay();
		setErrors({ errors: errors || {} });
		if (errors) {
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			const {
				amount,
				startDate,
				endDate,
				repeatIntervalDay,
				repeatIntervalWeek,
				weekday,
				dayOfMonth,
				frequency,
			} = autoWithdrawalForm;
			const type = dayOfMonth !== "" ? "fixed" : "flexible";
			const duration =
				frequency === "custom"
					? repeatIntervalWeek
					: frequency === "daily" || frequency === "2-days"
					? "days"
					: frequency === "weekly"
					? "weeks"
					: undefined;
			const durationLength =
				frequency === "custom"
					? repeatIntervalDay
					: frequency === "daily" || frequency === "weekly"
					? 1
					: frequency === "2-days"
					? 2
					: undefined;

			const userPin = Number(
				passwordForm.newpin1 +
					passwordForm.newpin2 +
					passwordForm.newpin3 +
					passwordForm.newpin4
			);
			// eslint-disable-next-line no-unused-vars
			const params = {
				amount,
				type,
				duration: duration,
				durationLength: durationLength,
				startDate: startDate,
				endDate: endDate !== "" ? endDate : null,
				dayOfWeek: weekday.toLocaleLowerCase() || undefined,
				pin: userPin,
				dayOfMonth:
					dayOfMonth !== "" || dayOfMonth > 0 ? dayOfMonth : undefined,
			};
			// dispatch(createWithdrawalSchedule(params));
		}
	};

	const handleScheduleUpdate = () => {
		const errors = validateAutopay();
		setErrors({ errors: errors || {} });
		if (errors) {
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			const {
				amount,
				startDate,
				endDate,
				repeatIntervalDay,
				repeatIntervalWeek,
				weekday,
				dayOfMonth,
				frequency,
			} = autoWithdrawalForm;

			const duration =
				frequency === "custom"
					? repeatIntervalWeek
					: frequency === "daily" || frequency === "2-days"
					? "days"
					: frequency === "weekly"
					? "weeks"
					: undefined;
			const durationLength =
				frequency === "custom"
					? repeatIntervalDay
					: frequency === "daily" || frequency === "weekly"
					? 1
					: frequency === "2-days"
					? 2
					: undefined;
			const id = memorizedSchedules[0]._id;
			const objToUpdate = memorizedSchedules[0];
			const userPin = Number(
				passwordForm.newpin1 +
					passwordForm.newpin2 +
					passwordForm.newpin3 +
					passwordForm.newpin4
			);
			const params = {
				amount: Number(amount),
				duration: duration,
				durationLength: durationLength,
				startDate: startDate || objToUpdate?.startDate?.slice(0, 10),
				endDate: endDate || objToUpdate?.endDate?.slice(0, 10),
				dayOfWeek: weekday.toLocaleLowerCase(),
				pin: userPin,
				dayOfMonth: dayOfMonth || objToUpdate.dayOfMonth,
			};
			dispatch(updateWithdrawalSchedule(params, id));
		}
	};
	const showNextSlide = () => {
		handleSetCurrent(7);
		setTimeout(() => {
			handleSetCurrent(8);
		}, 2000);
	};

	const checkFrequency = (schedule) => {
		let frequency = "";
		const { endDate, durationLength, duration } = schedule;
		if (endDate !== null) {
			frequency = "custom";
		} else if (durationLength === 2) {
			frequency = "2-days";
		} else if (durationLength === 1 && duration === "weeks") {
			frequency = "weekly";
		} else if (durationLength === 1 && duration === "days") {
			frequency = "daily";
		}
		return frequency;
	};

	// withdrawal rule set
	const displayWithdrawalFees = () => {
		let withdrawalForm2 = {};
		const { amount, employerId } = withdrawalForm;
		let amountBalanceWithdrawal = 0;

		if (amount >= 500) {
			dispatch(getWithdrawalFee({ amount, employerId }))
				.then(() => {
					let transactionFee = Number(withdrawalFee.fee);
					if (paymentFeeBy === "employee") {
						amountBalanceWithdrawal = Number(amount - transactionFee);
					} else if (paymentFeeBy === "employer") {
						transactionFee = 0;
						amountBalanceWithdrawal = amount;
					} else if (paymentFeeBy === "shared") {
						transactionFee = transactionFee / 2;
						amountBalanceWithdrawal = Number(amount - transactionFee);
					}
					withdrawalForm2 = {
						...withdrawalForm,
						transactionFee,
						amountBalanceWithdrawal,
					};

					setWithdrawalForm(withdrawalForm2);
				})
				.catch((err) => {
					withdrawalForm2 = {
						...withdrawalForm,
						transactionFee: 0,
						amountBalanceWithdrawal: 0,
					};
					setWithdrawalForm({
						...withdrawalForm,
						transactionFee: 0,
						amountBalanceWithdrawal: 0,
					});
				});
		} else {
			setWithdrawalForm({
				...withdrawalForm,
				transactionFee: 0,
				amountBalanceWithdrawal: 0,
			});
		}
	};

	const getWithdrawalSettings = () => {
		let autoWithdrawals = "";
		let withdrawalPercent;
		let paymentFeeBy = "";
		if (allDashboard?.dashboard) {
			const {companyInfo} = allDashboard?.dashboard;
			if (
				groupPolicy &&
				groupPolicy !== null &&
				groupPolicy !== "" &&
				Object.keys(groupPolicy).length !== 0
			) {
				autoWithdrawals = groupPolicy.autoWithdrawals;
				withdrawalPercent = groupPolicy.limit;
				paymentFeeBy = companyInfo?.paymentFee;
			} else {
				autoWithdrawals = companyInfo?.autoWithdrawals;
				withdrawalPercent = companyInfo?.withdrawalPercent;
				paymentFeeBy = companyInfo?.paymentFee;
			}
		}
		setAutoWithdrawals(autoWithdrawals);
		setWithdrawalPercent(withdrawalPercent);
		setPaymentFeeBy(paymentFeeBy);
	};

	const allowWithdrawal = () => {
		let response = true;
		const date = new Date();
		const currentDay = "" + date.getDate();
		const salaryCutOffDate = companyInfo?.payrollCutOff - 3;
		if (currentDay > salaryCutOffDate) {
			toast.error(
				`You can't withdraw after ${salaryCutOffDate}th, which is your company's cut-off date.`
			);
			response = false;
		} else {
			if (Number(withdrawalForm.amount) > balanceWithdrawable) {
				toast.error(
					"Sorry, your withdrawable salary balance is " +
						CurrencyFormat(balanceWithdrawable)
				);
				response = false;
			}
		}

		return response;
	};

	// withdrawal rule set ends
	const handleToggleModalWithdrawal = () => {
		if (user?.kycStatus === "approved"){
		cloaseModalAndClearFormFields();
		setOpen(!open);
		dispatch(resetReloadPage());
		}
		else{
			setIsOpenKycRequired(!isOpenKycRequired);
		}
	};

	const handleToggleNotification = () => {
		history.push("/notifications");
	};
	const handleChange = (e) =>
		setWithdrawalForm({
			...withdrawalForm,
			employerId: employerId,
			[e.target.name]: e.target.value,
		});

	const schema = {
		amount: Joi.number()
			.required()
			.min(500)
			.max(1500000)
			.label("Net Monthly Salary")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "number.base":
							err.message = `Please enter the amount to withdraw`;
							break;
						case "number.min":
							err.message = `Minimum withdrawal amount per transaction: ₦2,000`;
							break;
						case "number.max":
							err.message = `Maximum withdrawal amount per transaction: ₦50,000`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		// destinationAccount: joi.string().required().label("Destination Account").error(errors => { }
		destinationAccount: Joi.string()
			.required()
			.label("Destination Account")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "any.empty":
							err.message = "Please select a destination account";
							break;
						default:
							break;
					}
				});
				return errors;
			}),
	};

	const validate = () => {
		const options = { abortEarly: false, allowUnknown: true };
		const result = Joi.validate(withdrawalForm, schema, options);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	const submitForm = async (e) => {
		logger("employeeweb-withdraw", user);
		// e.preventDefault();
		const errors = validate();
		setErrors({ errors: errors || {} });
		if (errors) {
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			const { amount, purpose } = withdrawalForm;

			/*
			const params = {
				withdrawAmount: amount,
				reason: purpose,
				platform: "web",
			};
			*/

			const pin = Number(passwordForm.newpin1 + passwordForm.newpin2 + passwordForm.newpin3 + passwordForm.newpin4);
			
			let params = {
				amount: (amount * 100).toString(),
				// walletId: walletDetails?.data?.walletId,
				accessPin: pin,
				reference: "FUND-EAR-" + Date.now(),
				description: "Earnipay wallet funding",
				reason: purpose,
				platform: "web",
			};

			if (params.reason === "100"){
				params = {
					...params,
					userReason: withdrawalForm.userReason
				};
			}

			if (allowWithdrawal()) {
				dispatch(newWithdrawal(params));
			}
		}
	};


	const submitForm1 = async (e) => {
		e.preventDefault();
		const errors = validate();
		setErrors({ errors: errors || {} });
		if (errors) {
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			const { amount } = withdrawalForm;
			if (current < 1) {
				handleNext(Number(amount));
			}
		}
	};

	const handleNext = (val) => dispatch(next(val));
	const handlePrev = () => dispatch(prev());

	const onboardToAutoPay = () => {
		// dispatch(activateAutopay());
		setTimeout(() => {
			handleTogglePayment(false);
		}, 1200);
	};

	const displayCountdown = () => {
		// const salaryDay = this.props.dashboard.companyInfo[0].payrollCutOff;
		const date = new Date();

		let day = date.getDate();
		let currentMonthIndex = date.getMonth() + 1;
		const currentMonth = MonthName(currentMonthIndex);
		const year = date.getFullYear();

		if (day <= 9) {
			day = "0" + day;
		}

		if (currentMonthIndex <= 9) {
			currentMonthIndex = "0" + currentMonthIndex;
		}

		const countdown = (
			year +
			"-" +
			currentMonthIndex +
			"-" +
			day +
			"T17:00:00"
		).toString();

		setCurrentMonth(currentMonth);
		setCoundown(countdown);
	};

	const handleComingsoon = () => {
		setOpenComingSoon(!openComingSoon);
	};
	// eslint-disable-next-line no-unused-vars
	const handleCutOff = () => {
		// console.log("ghjk");
		setOpenCutOff(!openCutOff);
	};
	const displayProgressBar = () => {
		if (allDashboard.dashboard !== undefined) {
			const date = new Date();
			let hour = date.getHours();
			if (hour > 17) {
				hour = 17;
			}
			const progressBar = Math.floor((hour / 17) * 100);
			setProgressBar(progressBar);
		}
	};

	useEffect(() => {
		const paymentStopDate = user.cutOffDate - 2;
		if (
			(paymentStopDate - date <= 3 && paymentStopDate - date >= 0) ||
			date >= paymentStopDate
		) {
			setIsCutOff(true);
		}
		//eslint-disable-next-line
	}, [date]);

	useEffect(() => {
		logger("employeeweb-dashboard", user);
		googleAnalyticsTrack();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (user?.employeeId !== undefined){
			dispatch(getDashboard());
		}
		listAllWithdrawals();

		if (allDashboard) {
			setTimeout(() => {
				handleTogglePayment();
			}, 500);
		}
		dispatch(getReasonsForWithdraw());

		//eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		if (allDashboard?.dashboard !== null) {
			getWithdrawalSettings();
			displayCountdown();
			displayProgressBar();
		}

		//eslint-disable-next-line
	}, [allDashboard]);

	useEffect(() => {
		// dispatch(getTermsOfTheDay());
		dispatch(AccountActions.getWalletDetails());
		// dispatch(AccountActions.getUserInfo());

		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (
			(schedule?.status === "success" ||
				updatedSchedule?.status === "success") &&
			isDeletedSchedule === false
		) {
			showNextSlide();
		}
		if (isDeletedSchedule === true) {
			handleSetCurrent(3);
			handleTogglePayment(false);
			clearAutoWithdrawalForm();
			setValidateAction("");
		} else {
			clearPasswordForm();
		}
		if (updatedSchedule?.status === "success") {
			clearAutoWithdrawalForm();
			setValidateAction("");
		} else {
			clearPasswordForm();
		}
		// dispatch(getAllWithdrawalSchedule());
		clearPasswordForm();
		//eslint-disable-next-line
	}, [schedule, updatedSchedule, isDeletedSchedule]);

	useEffect(() => {
		if (
			secheduleGet.status === "success" &&
			Object.keys(secheduleGet.schedule).length !== 0
		) {
			handleSetCurrent(10);
			handleInputScheduleOnEdit(
				"frequency",
				checkFrequency(secheduleGet.schedule)
			);
			handleInputScheduleOnEdit("weekday", secheduleGet.schedule.dayOfWeek);
			handleInputScheduleOnEdit("amount", secheduleGet.schedule.amount);
		}

		//eslint-disable-next-line
	}, [secheduleGet]);

	useEffect(() => {
		if (newWithdrawalSuccess === true) {
			toast.success("Withdrawal successful");
			logger("employeeweb-withdraw-success", user);
			setWithdrawalForm({ ...withdrawalForm, amount: 0, purpose: "" });
			dispatch(getDashboard());
			dispatch(resetNewWithdrawal());
			setOpen(false);
			listAllWithdrawals();
			handlePrev();
		}
		//eslint-disable-next-line
	}, [newWithdrawalSuccess]);

	const type = autoWithdrawalForm.amount <= 15000 ? "daily" : "weekly";
	const calculateEstimatedFlexCharge = (amount) => {
		dispatch(getAutopayFee({ amount, employerId, type }));
	};
	useEffect(() => {
		const { amount } = withdrawalForm;
		if (amount >= 500) calculateEstimatedFlexCharge(amount);
		//eslint-disable-next-line
	}, [withdrawalForm.amount]);

	useEffect(() => {
		const { amount } = autoWithdrawalForm;
		if (amount >= 500) calculateEstimatedFlexCharge(amount);
		//eslint-disable-next-line
	}, [autoWithdrawalForm.amount]);

	const setFutureDateByDay = (num) => {
		const day = new Date();
		const computed = num < 0 ? 7 - Math.abs(num) : num;
		// format(new Date(2014, 1, 11), 'MM/dd/yyyy')
		return format(day.setDate(new Date().getDate() + computed), "EE, dd/LL/yy");
	};

	const setPayementDueDate = (i) => {
		const todayDate = new Date();
		const nextPayment = format(todayDate, "dd/LL/yy");
		const selectedDay = i;
		const currentDay = todayDate.getDay();
		if (selectedDay === currentDay) {
			return setPaymentDueDate(
				todayDate.getHours() < 17 ? nextPayment : setFutureDateByDay(7)
			);
		}
		const x = selectedDay - currentDay;
		return setPaymentDueDate(setFutureDateByDay(x));
	};

	const handleDaySelectLog = (frequency, weekday = "") => {
		const dayToLowercase = weekday.toLowerCase();
		if (frequency === "days") {
			logger("employeeweb-flexi-daily-schedule-success", user);
		} else if (frequency === "weeks") {
			if (dayToLowercase === "monday") {
				logger("employeeweb-flexi-schedule-day-monday", user);
			} else if (dayToLowercase === "tuesday") {
				logger("employeeweb-flexi-schedule-day-tuesday", user);
			} else if (dayToLowercase === "wednesday") {
				logger("employeeweb-flexi-schedule-day-wednesday", user);
			} else if (dayToLowercase === "thursday") {
				logger("employeeweb-flexi-schedule-day-thursday", user);
			} else if (dayToLowercase === "friday") {
				logger("employeeweb-flexi-schedule-day-friday", user);
			} else if (dayToLowercase === "saturday") {
				logger("employeeweb-flexi-schedule-day-saturday", user);
			} else if (dayToLowercase === "sunday") {
				logger("employeeweb-flexi-schedule-day-sunday", user);
			}
			logger("employeeweb-flexi-weekly-schedule-success", user);
		}
	};
	useEffect(() => {
		if (schedule?.status === "success") {
			handleDaySelectLog(
				schedule.schedule.duration,
				schedule.schedule.dayOfWeek
			);
		}
		//eslint-disable-next-line
	}, [schedule]);

	const CountdownRenderer2 = ({ completed }) => {
		if (completed) {
			return <>It's Payday</>;
		} else {
			return <>Before Payday</>;
		}
	};

	const CountdownRenderer1 = ({ hours, minutes, seconds }) => {
		return (
			<>
				<span style={{ color: "#44476E" }}>{zeroPad(hours)}</span>
				<sup
					className="text-xs relative -top-2 mr-2"
					style={{ color: "#A1A3B6" }}>
					H
				</sup>
				<span style={{ color: "#747793" }}>{zeroPad(minutes)}</span>
				<sup
					className="text-xs relative -top-2 mr-2"
					style={{ color: "#A1A3B6" }}>
					M
				</sup>
				<span style={{ color: "#ADAEBF" }}>{zeroPad(seconds)}</span>
				<sup
					className="text-xs relative -top-2 mr-2"
					style={{ color: "#A1A3B6" }}>
					S
				</sup>
			</>
		);
	};

	return (
		<>
			<DynamicHeadTag
				headerText="Dashboard"
				headerDescription="A central hub to access and manage your earnings,  account information and activities in one place."
			/>

			<WithdrawalModal
				open={open}
				handleToggleModalWithdrawal={handleToggleModalWithdrawal}
				handleChange={handleChange}
				amount={withdrawalForm.amount}
				withdrawalForm={withdrawalForm}
				purpose={withdrawalForm.purpose}
				errors={errors}
				setErrors={setErrors}
				handleNext={handleNext}
				handlePrev={handlePrev}
				current={current}
				withdrawalParams={withdrawalParams}
				withdrawalFee={withdrawalFee}
				user={user}
				employerId={employerId}
				getDashboard={getDashboard}
				dispatch={dispatch}
				submitForm={submitForm}
				submitForm1={submitForm1}
				displayWithdrawalFees={displayWithdrawalFees}
				transactionFee={withdrawalFee.fee}
				withdrawalFeeLoading={withdrawalFeeLoading}
				paymentFeeBy={paymentFeeBy}
				amountBalanceWithdrawal={withdrawalForm.amountBalanceWithdrawal}
				destinationAccount={withdrawalForm.destinationAccount}
				loadingNewWithdrawal={loadingNewWithdrawal}
				newlyWithdraw={newlyWithdraw}
				withdrawalReasons={withdrawalReasons}
				newWithdrawalSuccess={newWithdrawalSuccess}
				memorizedReasonWithdrawal={memorizedReasonWithdrawal}
				loadingReasons={loadingReasons}
				successReasonWithdrawal={successReasonWithdrawal}
				userReason={withdrawalForm.userReason}
				passwordForm={passwordForm}
				bgCol={bgCol}
				addBgCol={addBgCol}
				formChange={formChange}
				cloaseModalAndClearFormFields={cloaseModalAndClearFormFields}
				autopayFeeLoading={autopayFeeLoading}
				autopayFee={autopayFee}
				handleSetCurrent={handleSetCurrent}
				allowAutopayToggle={allowAutopayToggle}
				walletDetails={walletDetails}
			/>

			{/* auto payment modal */}
			<div>
				{loadingDashboard === false && allDashboard && open2 === true && (
					<PaymentModal
						open={open2}
						handleTogglePayment={handleTogglePayment}
						current={currentScreenSchedule}
						handleSetCurrent={handleSetCurrent}
						handleChange={handleChangeAutoPay}
						employerId={employerId}
						submitForm={handleCreateSchedule}
						autoWithdrawalForm={autoWithdrawalForm}
						bgCol={bgCol}
						addBgCol={addBgCol}
						formChange={formChange}
						passwordForm={passwordForm}
						memorizedDetails={memorizedDetails}
						memorizedWithdrawals={memorizedWithdrawals}
						displayWithdrawalFees={displayAutopayFees}
						withdrawalFee={withdrawalFee}
						paymentFeeBy={paymentFeeBy}
						loadingCreateWithdrawal={loadingCreateWithdrawal}
						loadingUpdate={loadingUpdate}
						loadingDelete={loadingDelete}
						loadingGetSchedule={loadingGetSchedule}
						schedule={schedule}
						schedules={schedules}
						memorizedSchedules={memorizedSchedules}
						getScheduleById={getScheduleById}
						secheduleGet={secheduleGet}
						deleteSchedulePayment={deleteSchedulePayment}
						handleDaySelectLog={handleDaySelectLog}
						handleInputScheduleOnEdit={handleInputScheduleOnEdit}
						handleScheduleUpdate={handleScheduleUpdate}
						onboardToAutoPay={onboardToAutoPay}
						autopayFeeLoading={autopayFeeLoading}
						autopayFee={autopayFee}
						clearAutoWithdrawalForm={clearAutoWithdrawalForm}
						paymentDueDate={paymentDueDate}
						setPayementDueDate={setPayementDueDate}
						autopayCancellationFee={autopayCancellationFee}
						loadingCancelAutopay={loadingCancelAutopay}
						cancelAutopaySchedule={cancelAutopaySchedule}
						validateAction={validateAction}
						setValidateAction={setValidateAction}
						active={active}
						setActive={setActive}
						clickedDate={clickedDate}
						setClickedDate={setClickedDate}
					/>
				)}
			</div>
			{/* first layer */}

			<Dialog initialFocus={completeButtonRef} open={isOpenKycRequired} onClose={setIsOpenKycRequired} className="fixed z-10 inset-0 overflow-y-auto">
				<div className="min-h-screen flex items-center justify-center rounded shadow-md">
					<Dialog.Overlay className="fixed inset-0 bg-black bg-blur bg-opacity-50" />
					<div className="max-w-sm mx-auto p-8 text-center relative bg-white rounded-lg shadow-md overflow-hidden">
						<div>
							<FontAwesomeIcon icon="exclamation-triangle" className="text-red-300 text-6xl" />
						</div>
						<div className="mt-4 font-semibold text-xl">
							KYC Required
						</div>
						<div className="mt-3 text-fade">
							Please complete your KYC on the Earnipay mobile app,
							to gain access to On-Demand Pay
						</div>
						<div className="mt-6 flex space-x-4">
							<button type="button" onClick={() => setIsOpenKycRequired(false)} className="btn btn-block btn-transparent-black">
								Dismiss
							</button>
						</div>
					</div>
				</div>
			</Dialog>

			<div className="w-full">
				<div className="sm:flex sm:justify-between pb-2 mb-4">
					<div>
						<div className="font-semibold font-recoleta text-xl lg:text-2xl flex items-center">
							<div className="font-semibold font-recoleta text-xl lg:text-2xl flex items-center capitalize">
								Hello {user?.name.split(" ")[0]} <IconWave className="ml-2" />
							</div>
						</div>
						<div className="text-fade font-normal text-sm md:text-base mt-1">
							Here's an overview of your earnings this month.
						</div>
					</div>

					<div>
						<div className=" text-base font-semibold flex justify-end relative">
							{/* 
							<FontAwesomeIcon
								icon="bell"
								className=" w-5 h-5 inline-block my-auto cursor-pointer"
								onClick={handleToggleNotification}
							/>
							 */}
							{memoizedNotes && memoizedNotes.length > 0 && (
								<div
									className="w-6 h-6 rounded-full bg-new-ep-blue text-center text-white absolute -top-3 -right-2 cursor-pointer"
									style={{ fontSize: "8px" }}
									onClick={handleToggleNotification}>
									{memoizedNotes.length}
								</div>
							)}
						</div>
						<div className="text-fade font-normal text-sm sm:text-base mt-1 ">
							{CurrentDate(" ")}
						</div>
					</div>
				</div>
			</div>
			
			<div className="" ref={completeButtonRef}></div>
			
			{user?.kycStatus !== "approved" &&
				<div className="mt-2">
					<BannerAlert
						// title="Incomplete KYC"
						content="Please complete your KYC on the mobile app"
					/>
				</div>
			}

			{walletDetails.status && walletDetails?.status !== "success" &&
				<div className="mt-2">
					<BannerAlert
						content="You do not have an earnipay account. Please log on the mobile app to get an account"
					/>
				</div>
			}

			<ComingSoonModal
				open={openComingSoon}
				toggleOpen={() => handleComingsoon(false)}
			/>

			{/* 
			<CutoffModal
				open={openCutOff}
				toggleOpen={() => handleCutOff(false)}
				user={user}
				handleCutOff={handleCutOff}
			/>
			 */}
			<div className="my-4 w-full md:flex justify-center space-x-0 lg:space-x-2 ">
				
						<>
							<div className="sm:w-full">
								{/* 
								{isCutOff === true && (
									<CutoffComponent
										handleCutOff={handleCutOff}
										user={user}
										date={date}
									/>
								)}
								 */}
								<div className="lg:flex sm:flex md:block justify-between sm:space-x-2">
									<div className="ep-card-blue sm:w-full mt-0 md:my-2 lg:my-0 dashboard-box-type-a dashboard-card-grey">

										<div className="py-4 flex justify-between">
											<div>
												<div className="font-sans text-fade text-sm md:text-base">
													Earnings Accrued ({`${date}/${daysOfMonth} days`})
												</div>
												<div className="font-semibold font-recoleta dashboard-card-grey-text text-xl lg:text-2xl">
													<ContentLoading
														loading={loadingDashboard}
														data={displayBalanceAccrued ? CurrencyFormat(totalAccured) ?? "-" : "****"}
													/>
												</div>
											</div>
											<div
												className="card-content align-top pt-1 pr-3 cursor-pointer"
												onClick={() => setDisplayBalanceAccrued(!displayBalanceAccrued)}>
												<FontAwesomeIcon
													icon={displayBalanceAccrued ? "eye" : "eye-slash"}
													className="text-lg text-new-ep-blue"
												/>
											</div>
										</div>
										<div className="py-4 flex justify-between">
											<div>
												<div className="font-sans text-fade text-sm md:text-base capitalize font-medium">
													Available Balance ({`${date}/${daysOfMonth} days`})
												</div>
												<div className="font-semibold font-recoleta dashboard-card-grey-text text-xl lg:text-2xl">
													<ContentLoading
														loading={loadingDashboard}
														data={displayBalanceAccrued ?
																allDashboard.dashboard ?
																	CurrencyFormat((totalAccured / 2) - totalAmountWithdrawn) ?? "-"
																: "-"
															: "****"}
													/>
												</div>
											</div>
											<div className="w-12 h-12 flex rounded-lg bg-yellow-100">
												<IconChartToggle className="m-auto svg-fill-primary" />
											</div>
										</div>

									</div>

									<div className="ep-card-blue sm:w-full mt-4 sm:mt-0 dashboard-box-type-a dashboard-card-gold">
										<div className="py-4 flex justify-between">
											<div>
												<div className="font-sans text-fade text-sm md:text-base">
													Account Balance
												</div>
												<div className="font-semibold font-recoleta dashboard-card-grey-text text-xl lg:text-2xl">
													<ContentLoading
														loading={walletDetailsLoading}
														data={displayBalanceWallet ? 
																walletDetails?.data?.balance ? CurrencyFormat(walletDetails?.data?.balance/100) ?? "-" : "-"
															: "****"}
													/>
												</div>
											</div>
											<div
												className="card-content align-top pt-1 pr-3 cursor-pointer"
												onClick={() => setDisplayBalanceWallet(!displayBalanceWallet)}>
												<FontAwesomeIcon
													icon={displayBalanceWallet ? "eye" : "eye-slash"}
													className="text-lg text-new-ep-blue"
												/>
											</div>
										</div>
										<div className="py-4 flex justify-between">
											<div>
												<div className="font-sans text-fade text-sm md:text-base capitalize font-medium">
													{walletDetails?.data?.bankName ?? ""}
												</div>
												<div className="font-semibold font-recoleta dashboard-card-grey-text text-xl lg:text-2xl">
													<ContentLoading
														loading={walletDetailsLoading}
														data={walletDetails?.data?.accountNumber ?? "-"}
													/>
													{walletDetails?.data?.accountNumber &&
														<FontAwesomeIcon
															icon="copy"
															className="ml-3 cursor-pointer"
															onClick={() => copyValue(walletDetails?.data?.accountNumber)}
														/>
													}
												</div>
											</div>
											<div className="w-12 h-12 flex rounded-lg bg-yellow-100">
												<IconBag className=" m-auto" />
											</div>
										</div>
									</div>
								</div>
								<div className="lg:flex sm:flex md:block justify-between lsm:space-x-2 mt-6">
									<div
										className="dashboard-box dashboard-box-colored bg-new-ep-blue dashboard-box-type-b w-full"
										style={{ height: "218px", border: "none" }}>
										<div className="flex justify-between">
											<div className="text-gray-50 text-sm md:text-base inline-block">
												<span className=" text-gray-50">
													Accrued Balance ({`${date}/${daysOfMonth} days`})
												</span>
												{/* 
												<div className="bg-white h-4 w-4 rounded-full text-center inline-block ml-2">
													<FontAwesomeIcon
														icon="exclamation"
														className="text-xs text-new-ep-blue"
													/>
												</div>
												*/}
											</div>
											<div
												className="card-content flex justify-center pt-1 cursor-pointer"
												onClick={() => setDisplayBalanceWithdraw(!displayBalanceWithdraw)}>
												<FontAwesomeIcon
													icon={displayBalanceWithdraw ? "eye" : "eye-slash"}
													className="text-xl text-new-ep-blue"
												/>
											</div>
										</div>
										<div className="font-semibold text-2xl sm:text-3xl text-white relative bottom-3">
											<ContentLoading
												loading={loadingDashboard}
												data={displayBalanceWithdraw ?
														allDashboard.dashboard ?
															CurrencyFormat(((withdrawalPercent / 100) * allDashboard.dashboard.totalAccured) - allDashboard.dashboard.totalAmountWithdrawn) ?? "-"
														: "-"
													: "****"}
											/>
										</div>
										<div className="flex mt-8">
											<div className=" flex-grow items-center font-bold text-2xl text-white">
												<button
													type="button"
													onClick={handleToggleModalWithdrawal}
													className="btn bg-white text-ep-blue text-sm md:text-lg px-4 py-2 sm:px-6 sm:py-3"
													disabled={user?.employeeId === undefined || allDashboard?.dashboard?.totalAccured === undefined}
												>
													<IconWithdraw className="customizeSVG text-new-ep-blue mr-2 w-5 h-5" />
													On-Demand Pay
												</button>
											</div>
											<div className="flex-shrink-0">
												<div className="w-12 h-12 text-center flex rounded-lg bg-yellow-100">
													<IconChartToggle className="svg-fill-primary m-auto" />
												</div>
											</div>
										</div>
									</div>
									<div className="p-6 rounded-lg w-full sm:mt-4 md:mt-4 lg:mt-0 flex">
										<div className="w-full my-auto">
											<div className="flex justify-between">
												<div>
													<div className="mt-2 text-2xl font-semibold h-8">
														{countdown !== "" && (
															<Countdown
																date={countdown}
																renderer={CountdownRenderer1}
															/>
														)}
													</div>
													<div className="text-lg">
														{countdown !== "" && (
															<Countdown
																date={countdown}
																renderer={CountdownRenderer2}
															/>
														)}
													</div>
												</div>
												<div className="w-12 h-12 flex rounded-lg bg-yellow-100 ml-2">
													<IconBag className=" bg-yellow-100 m-auto" />
												</div>
											</div>
											<div className="mt-6 flex justify-between space-x-2">
												<div
													className="h-2 mt-2 flex-grow rounded-full"
													style={{ backgroundColor: "#E4ECF7" }}>
													<div
														className="h-full rounded-full duration-1000"
														style={{
															width: `${progressBar}%`,
															backgroundColor: "#00535C",
														}}></div>
												</div>
												<div
													className="font-bold w-1/6 pl-8 self-end"
													style={{ color: "#374151" }}>
													{progressBar}%
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					
			</div>

			<div className="mt-10 mb-28">
				<div
					className="rounded-lg overflow-hidden"
					style={{ backgroundColor: "#F7FAFC" }}>
					<div className="md:h-56 md:flex md:justify-between bg-spiral-under">
						<div className="p-6 md:p-0 flex flex-grow">
							<div className="text-center md:text-left mx-auto md:mx-0 md:pl-10 2xl:pl-20 my-auto">
								{/* 
								<div className="font-bold text-gray-500">
									Also available via USSD
								</div>
								<div className="mt-1 text-ep-blue text-4xl font-semibold font-recoleta">
									*347*729#
								</div>
								*/}
								<div className="font-semibold text-3xl">
									Download the <br />
									Earnipay app
								</div>
								<div className="mt-2 flex space-x-2 justify-center sm:justify-start">
									{/* <Link to={{pathname:"https://testflight.apple.com/join/Sh3Zu8YG"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="inline-block h-8 mt-2" target="_blank" rel="noreferrer"> */}
									<Link
										to={{
											pathname:
												"https://apps.apple.com/ng/app/earnipay/id1582293975y",
										}}
										onClick={(e) => {
											window.open(
												"https://apps.apple.com/ng/app/earnipay/id1582293975",
												"_blank",
												"noopener,noreferrer"
											);
											e.preventDefault();
										}}
										className="inline-block h-10 mt-2">
										<img
											src={appStore}
											className="cursor-pointer h-full"
											alt="apple appstore"
										/>
									</Link>
									{/* <Link to={{pathname:"https://play.google.com/store/apps/details?id=com.earnipay.earnipay"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="inline-block h-8 mt-2" target="_blank" rel="noreferrer"> */}
									<Link
										to={{
											pathname:
												"https://play.google.com/store/apps/details?id=com.earnipay.earnipay",
										}}
										onClick={(e) => {
											window.open(
												"https://play.google.com/store/apps/details?id=com.earnipay.earnipay",
												"_blank",
												"noopener,noreferrer"
											);
											e.preventDefault();
										}}
										className="inline-block h-10 mt-2">
										<img
											src={playStore}
											className="cursor-pointer h-full"
											alt="google playstore"
										/>
									</Link>
								</div>
							</div>
						</div>
						<div
							className="hidden xl:block h-full relative flex-shrink-0 mt-6"
							style={{ width: "450px" }}>
							<img
								src={phoneDownload}
								className="max-w-full max-h-full absolute bottom-0"
								alt="phoneDownload"
							/>
						</div>
						<div
							className="hidden lg:block xl:hidden h-full relative flex-shrink-0 mt-6"
							style={{ width: "550px" }}>
							<img
								src={phoneDownload}
								className="max-w-full max-h-full absolute bottom-0"
								alt="phoneDownload"
							/>
						</div>
						<div className="hidden md:block lg:hidden w-96 h-full relative flex-shrink-0 mt-6">
							<img
								src={phoneDownload}
								className="max-w-full max-h-full absolute bottom-0"
								alt="phoneDownload"
							/>
						</div>
						<div className="block md:hidden mt-6">
							<img
								src={phoneDownload}
								className="max-w-full max-h-full"
								alt="phoneDownload"
							/>
							{/* <img src={phonemint} className="max-w-1/2 max-h-full bottom-0 inline-block" alt="phoneDownload" /> */}
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Dashboard;
